import React from 'react'
import styled from "@emotion/styled";

const App = () => {
  const iframeRef = React.useRef(null);
  return <div>
    <Iframe src={"https://nouramusic.wixsite.com/monsite"} ref={iframeRef}/>
    <HiddenWix>
      <Logo src={require('./assets/logo_noura.png')} alt="logo"/>
    </HiddenWix>
  </div>
}

const Logo = styled.img`
    position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
`

const HiddenWix = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100px;
`

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
`

export default App
